import * as React from "react"
import type { SVGProps } from "react"
const SvgXLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M11.902 8.465 19.348 0h-1.766l-6.465 7.348L5.957 0H0l7.809 11.113L0 19.988h1.766l6.824-7.761 5.453 7.761H20ZM9.484 11.21l-.789-1.106-6.297-8.804H5.11l5.082 7.105.79 1.106 6.605 9.238h-2.711Zm0 0"
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fillOpacity: 1,
      }}
    />
  </svg>
)
export default SvgXLogo
