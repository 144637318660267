"use client"

import * as Form from "@radix-ui/react-form"
import { Control, Controller } from "react-hook-form"
import { cn } from "@store-platform/utils"
import { SvgDownload } from "@store-platform/ui/icons"

export interface FormFieldFileProps {
  name: string
  control: Control<any>
  label: string
  error?: string
  className?: string
  accept?: string[]
}

export function FormFieldFile(props: FormFieldFileProps) {
  return (
    <Form.Field name={props.name}>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => (
          <label
            className={cn(
              "flex items-center w-fit border gap-2 h-11 px-4 py-2 bg-blue-500 hover:bg-blue-500/90 text-white",
              "rounded-lg text-sm shadow-sm hover:shadow cursor-pointer",
              props.className,
            )}
          >
            <input
              {...field}
              value={field.value?.fileName}
              onChange={(event) => {
                if (event.target.files !== null)
                  field.onChange(event.target.files[0])
              }}
              type="file"
              className="hidden"
              accept={props.accept?.join(",")}
            />
            <SvgDownload />
            {props.label} {field.value?.fileName}
          </label>
        )}
      />
    </Form.Field>
  )
}
