// copied from node_modules/next/types/global.d.ts
interface NextFetchRequestConfig {
  revalidate?: number | false
  tags?: string[]
}

export type BackendEndpointDef<TData, TError> = {
  method: "get" | "post" | "put" | "patch" | "delete" | "head"
  path: string
  data?: any
  queryParams?: {
    [key: string]:
      | string
      | number
      | boolean
      | string[]
      | number[]
      | boolean[]
      | undefined
  }
  options?: {
    form?: boolean
    next?: NextFetchRequestConfig | undefined
    authenticated?: boolean
    cache?: RequestCache
  }
  on?: {
    error?: OnApiErrorCallback<TError>
    success?: OnApiSuccessCallback<TData>
  }
}

export type BackendEndpoint<TData, TError = undefined> = (
  ...params: any[]
) => BackendEndpointDef<TData, TError>

export class ApiError<TDetails = undefined> extends Error {
  constructor(
    message: string,
    public readonly status: number,
    public readonly code?: string | undefined,
    public readonly details?: TDetails,
  ) {
    super(message)
  }
}

export type BackendResponse<TData, TError = undefined> = Promise<
  | {
      data: TData
      error: null
    }
  | {
      data: null
      error: ApiError<TError>
    }
>

type OnApiErrorCallback<TError> = (error: ApiError<TError>) => void
type OnApiSuccessCallback<TData> = (data: TData) => void

export type BackendApi = <TData, TError>(
  def: BackendEndpointDef<TData, TError>,
) => BackendResponse<TData, TError>

export type AnalyticsUser = {
  id?: string | null
  token: string | null
  anonymousId: string | undefined
}

export type AnalyticsTracker = {
  obj: string
  action: string
  properties?: { appId?: string } & Record<string, string | undefined> // backend is required to receive strings
}
