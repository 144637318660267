import { PLATFORM_DESKTOP } from "@store-platform/types"
import { Badge } from "../Badge/Badge"
import {
  parsePlatformsFromAttributes,
  platformIcons,
} from "@store-platform/utils"

export const PlatformBadges = ({
  attributes = [],
}: {
  attributes: string[]
}) => {
  const platforms = parsePlatformsFromAttributes(attributes)
  let filtered = platformIcons.filter((platform) =>
    platforms.includes(platform.id),
  )

  if (!filtered.length)
    filtered = [
      platformIcons.find(
        (p) => p.id === PLATFORM_DESKTOP,
      ) as (typeof platformIcons)[0],
    ]

  return filtered.map((p) => (
    <Badge key={p.id} variant="secondary" className="p-1 aspect-1">
      <p.Icon className="w-3 h-3" />
    </Badge>
  ))
}
