"use client"
import { Listing } from "@store-platform/types"
import { cn, formatListing, getBestIcon } from "@store-platform/utils"
import { AppIcon } from "../AppIcon/AppIcon"
import { Link } from "../Link/Link"
import PWAIcon from "../PWAIcon/PWAIcon"
import { ClaimVerifiedListingBadge } from "../Badges/ClaimVerifiedListingBadge"
import { ClaimedListingBadge } from "../Badges/ClaimedListingBadge"
import { useMemo } from "react"
import { useAttributeState } from "../../hooks"

export const SearchDropdownResult = ({
  listing,
  selected,
  asLink = true,
  onClick = () => null,
}: {
  listing: Listing
  selected: boolean
  asLink?: boolean
  onClick?: () => void
}) => {
  const { attributeMap } = useAttributeState()
  const { icons, developerDetails, isPWA, attributes } =
    formatListing(listing) || {}

  const categories = attributes?.filter((slug) => slug.startsWith("category."))
  const categoriesString = categories
    ?.map((slug) => attributeMap[slug]?.name)
    .join(", ")

  const claimed = !!developerDetails?.username
  const developerVerified = developerDetails?.verified
  const iconSrc = useMemo(() => getBestIcon(icons)?.src, [icons])

  const Component = asLink ? Link : "div"

  return (
    <Component
      variant="naked"
      tracker={{
        obj: "Search",
        action: "Result Interacted",
        properties: {
          subject: "App",
          listingId: listing.id,
        },
      }}
      href={`/${listing.id}`}
      onClick={onClick}
      className={cn(
        "flex cursor-pointer w-full flex-row items-center justify-between rounded-lg p-2",
        { "bg-gray-50": selected },
      )}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center space-x-2">
          <AppIcon className="w-8 h-8" imgUrl={iconSrc} size={48} />
          <div className="flex flex-col">
            <div className="inline-flex items-center gap-x-1">
              <h3 className="text-sm font-semibold text-gray-900">
                {listing.name}
              </h3>
              <span className="inline-flex">
                {claimed &&
                  (developerVerified ? (
                    <ClaimVerifiedListingBadge />
                  ) : (
                    <ClaimedListingBadge />
                  ))}
                {isPWA && <PWAIcon />}
              </span>
            </div>
            <p className="inline-flex items-center whitespace-nowrap text-xs font-light text-gray-500">
              {categoriesString}
            </p>
          </div>
        </div>
      </div>
    </Component>
  )
}
