import { useAuth, useSession, useUser } from "@clerk/nextjs"
import {
  AnalyticsTracker,
  BackendApi,
  backendApiCall,
  BackendEndpointDef,
  cookieNameAnonymousId,
  JwtBackendTemplate,
} from "@store-platform/backend/common"
import { hasRole } from "@store-platform/utils"
import { useCallback, useEffect } from "react"
import { create } from "zustand"
import {
  trackClientEvent,
  trackClientIdentify,
  trackPageView,
} from "./analytics"

const cookieRegex = new RegExp(`(^| )${cookieNameAnonymousId}=([^;]+)`)

type AnalyticsTrackersStore = {
  ready: boolean
  anonymousId?: string | undefined
  setAnonymousId: (id: string | undefined) => void
}

const useAnalyticsTrackers = create<AnalyticsTrackersStore>((set) => ({
  ready: false,
  anonymousId: undefined,
  setAnonymousId: (id: string | undefined) =>
    set({ anonymousId: id, ready: true }),
}))

export const useAnalytics = () => {
  const { isSignedIn, getToken, isLoaded: isAuthLoaded } = useAuth()
  const { session, isLoaded: isSessionLoaded } = useSession()
  const { ready, setAnonymousId, anonymousId } = useAnalyticsTrackers()

  useEffect(() => {
    if (!ready && isSessionLoaded && isAuthLoaded) {
      const cookieMatch = document.cookie.match(cookieRegex)
      const anonymousId = cookieMatch ? cookieMatch[2] : undefined
      setAnonymousId(anonymousId)
    }
  }, [ready, isAuthLoaded, isSessionLoaded, isSignedIn, session])

  const trackEvent = useCallback(
    (tracker: AnalyticsTracker) => {
      (isSignedIn
        ? getToken({ template: JwtBackendTemplate })
        : Promise.resolve(null)
      ).then((token) => {
        trackClientEvent(
          {
            id: session?.user.externalId,
            anonymousId,
            token,
          },
          tracker,
        ).then()
      })
    },
    [session?.user.externalId, anonymousId, isSignedIn, getToken],
  )

  const trackPage = useCallback(
    (pathname: string, search?: URLSearchParams) => {
      (isSignedIn
        ? getToken({ template: JwtBackendTemplate })
        : Promise.resolve(null)
      ).then((token) => {
        trackPageView(
          {
            id: session?.user.externalId,
            anonymousId,
            token,
          },
          pathname,
          search,
        ).then()
      })
    },
    [session?.user.externalId, anonymousId, isSignedIn, getToken],
  )

  const trackIdentify = useCallback(() => {
    (isSignedIn
      ? getToken({ template: JwtBackendTemplate })
      : Promise.resolve(null)
    ).then((token) => {
      trackClientIdentify({
        id: session?.user.externalId,
        anonymousId,
        token,
      }).then()
    })
  }, [session?.user.externalId, anonymousId, isSignedIn, getToken])

  return {
    trackerReady: ready,
    trackEvent,
    trackPage,
    trackIdentify,
  }
}

export const useBackendApi = (): BackendApi => {
  const { sessionId, getToken } = useAuth()

  return async function <TData, TError = undefined>(
    def: BackendEndpointDef<TData, TError>,
  ) {
    return backendApiCall<TData, TError>(
      def,
      sessionId ? await getToken({ template: JwtBackendTemplate }) : null,
    )
  }
}

export const useRole = () => {
  const { user, isSignedIn } = useUser()
  return isSignedIn
    ? {
        isUser: true,
        isDev: hasRole(user.publicMetadata, "developer"),
        isAdmin: hasRole(user.publicMetadata, "admin"),
      }
    : {
        isUser: false,
        isDev: false,
        isAdmin: false,
      }
}
