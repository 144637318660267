import { camelCase, isPlainObject, snakeCase } from "lodash-es"
import { Listing } from "@store-platform/types"
import { isPwa } from "./listing"

export function camelCaseKeysRecursive(obj: any): any {
  if (isPlainObject(obj)) {
    const newObj: { [key: string]: any } = {}
    Object.keys(obj).forEach((k) => {
      newObj[camelCase(k)] = camelCaseKeysRecursive(obj[k])
    })
    return newObj
  } else if (Array.isArray(obj)) {
    return obj.map((i) => camelCaseKeysRecursive(i))
  }
  return obj
}

export function snakeCaseKeysRecursive(obj: any): any {
  if (isPlainObject(obj)) {
    const newObj: { [key: string]: any } = {}
    Object.keys(obj).forEach((k) => {
      newObj[snakeCase(k)] = snakeCaseKeysRecursive(obj[k])
    })
    return newObj
  } else if (Array.isArray(obj)) {
    return obj.map((i) => snakeCaseKeysRecursive(i))
  }
  return obj
}

type ListingResponse = Listing & {
  icons?: {
    src: string
    sizes: string
    purpose?: string
  }[]
  tags?: string[]
}

export function formatListing(listing: ListingResponse): Listing {
  const { links = [] } = listing

  const isPWA = isPwa(listing?.attributes || [])
  const launchLinks = links.filter(
    (link) => link.type === "install" || link.type === "direct_install",
  )
  const webAppIndex = launchLinks.findIndex(
    (link) => link.platform === "web_app",
  )
  const websiteIndex = launchLinks.findIndex(
    (link) => link.platform === "website",
  )
  if (webAppIndex !== -1 && websiteIndex !== -1) {
    launchLinks.splice(websiteIndex)
  }

  const isApproved = isAppApproved(listing?.tags || [])

  return {
    ...listing,
    isPWA,
    isApproved,
    launchLinks,
    developerDetails: listing.developerDetails,
  }
}

export const isAppApproved = (tags: string[]) => tags.length < 0 // todo implement
