"use client"

import { Listing } from "@store-platform/types"
import {
  useAttributeState,
  useIsInstalled,
  useIsMobileListingFooterOpen,
  useUserAgentOS,
} from "../../hooks"
import { AppIcon } from "../AppIcon/AppIcon"
import { cn, getBestIcon } from "@store-platform/utils"
import { isEmpty } from "lodash-es"
import { LaunchButton } from "../LaunchButton/LaunchButton"
import React from "react"
import { BadgeGroup } from "../BadgeGroup/BadgeGroup"
import { PlatformBadges } from "../Badges/PlatformBadges"
import { AttributeBadges } from "../Badges/AttributeBadges"
import { ClaimVerifiedListingBadge } from "../Badges/ClaimVerifiedListingBadge"
import { ClaimedListingBadge } from "../Badges/ClaimedListingBadge"
import { PWAIcon } from "../PWAIcon/PWAIcon"

export const MobileListingFooter = React.memo(
  ({ listing }: { listing: Listing | undefined }) => {
    const { attributeMap } = useAttributeState()
    const isMobileListingFooterOpen = useIsMobileListingFooterOpen()
    const isInstalled = useIsInstalled()
    const isiOS = useUserAgentOS()?.name === "iOS"

    if (!listing) return null

    const { id, name, attributes, icons, developerDetails, isPWA } = listing

    if (!id) return null
    const claimedByOwner = !isEmpty(developerDetails)
    const { verified: developerVerified } = developerDetails || {}

    const expandedHeight = isInstalled && isiOS ? "h-[88px]" : "h-20"

    return (
      <div
        className={cn(
          "fixed bottom-0 z-40 flex w-screen min-w-full flex-row border-t-[1px] border-t-gray-100 bg-white transition-all duration-300 ease-in-out",
          isMobileListingFooterOpen ? expandedHeight : "h-0",
        )}
      >
        <div className="m-3 w-full">
          <div className="flex w-full justify-between">
            <div className="flex flex-row gap-x-4 flex-1">
              <AppIcon
                imgUrl={getBestIcon(icons)?.src}
                size={96}
                className="h-14 w-14"
              />

              <div className="flex flex-col flex-1 justify-start">
                <span className="inline-flex items-center gap-x-1">
                  <p
                    className={cn(
                      "text-gray-900 whitespace-nowrap truncate",
                      name?.length > 18 ? "text-lg" : "text-xl",
                    )}
                    style={{
                      //168: margin (12) + icon (56) + gap (16) + launch btn (68) + gap(16)
                      maxWidth: `calc(100vw - ${168 + (isPWA ? 24 : 0)}px)`,
                    }}
                  >
                    {name}
                  </p>
                  {isPWA && <PWAIcon className="flex-shrink-0" />}
                  {claimedByOwner &&
                    (developerVerified ? (
                      <ClaimVerifiedListingBadge />
                    ) : (
                      <ClaimedListingBadge />
                    ))}
                </span>
                <BadgeGroup className="flex grow w-full">
                  <PlatformBadges attributes={attributes || []} />
                  <AttributeBadges
                    attributes={
                      listing?.attributes?.filter((attr) =>
                        attr.startsWith("category."),
                      ) || []
                    }
                    attributeMap={attributeMap}
                    type="primary"
                  />
                </BadgeGroup>
              </div>
            </div>
            <div className="flex items-center">
              <LaunchButton listing={listing} trackerSource="Listing Footer" />
            </div>
          </div>
        </div>
      </div>
    )
  },
)
