"use client"

import * as React from "react"
import { cva, VariantProps } from "class-variance-authority" // import { TrackOnClick } from '../../shared/components/ui/tracker.hoc';
import Link from "next/link"
import { cn } from "@store-platform/utils"
import { useAnalytics } from "@store-platform/backend/api-client"
import { AnalyticsTracker } from "@store-platform/backend/common"

/*
 *** Analytics
 */
type ButtonAction = "click" | "open" | "none"

type ButtonTracker =
  | "none"
  | "back_arrow"
  | "sign_up"
  | "login"
  | "forgot_password"
  | "reset_password"
  | "resend_verification_email"
  | "contact_mailto"
  | "mobile_search_icon"
  | "get_app"
  | "share_view"
  | "share_view_copy_link"
  | "share_view_to_twitter"
  | "all_filters"
  | "favorite_app"
  | "view_lighthouse_report"
  | "share_app"
  | "view_social"
  | "write_review"
  | "update_review"
  | "star_rating"
  | "plan_payment_settings"
  | "free_plan_card_action"
  | "verified_plan_card_action"
  | "growth_plan_card_action"
  | "plan_card_action"
  | "confirm_payment"
  | "open_stripe_dashboard"
  | "start_kyc_verification"
  | "claim_app_dev_portal"
  | "cta_boost_listing"
  | "cta_complete_verification"
  | "cta_enhance_listing"
  | "cta_get_listed"
  | "cta_get_dev_access"
  | "claim_domain_submit_url"
  | "claim_domain_submit_app"
  | "claim_domain_close_modal"
  | "claim_domain_ready_to_verify"
  | "claim_domain_exit_modal"
  | "refetch_manifest"
  | "view_listing"
  | "open_install_modal_demo"
  | "profile_go_edit"
  | "settings_profile_edit"
  | "settings_account_edit"

  // mobile home footer
  | "mobile_home_footer_browse"
  | "mobile_home_footer_search"
  | "mobile_home_footer_foryou"
  | "mobile_home_footer_developer"

/*
 *** Variants
 */

export type ButtonVariant =
  | "default"
  | "destructive"
  | "outline"
  | "ghost"
  | "secondary"
  | "neutral"
  | "link"
  | "naked"
  | "warning"

export type ButtonSize = "default" | "sm" | "lg" | "icon" | "flex"

interface ButtonVariants {
  variants: {
    variant: {
      [variant in ButtonVariant]: string
    }
    size: {
      [size in ButtonSize]: string
    }
  }
  defaultVariants: {
    variant: ButtonVariant
    size: ButtonSize
  }
}

const buttonVariantConfig: ButtonVariants = {
  variants: {
    variant: {
      naked: "",
      default: "bg-blue-500 text-white hover:bg-blue-500/90",
      destructive: "", // TODO! - ADD ERROR BTN
      outline: "border border-blue-50 text-blue-500 hover:bg-blue-50",
      ghost: "bg-gray-50 text-gray-700 disabled:text-gray-500 hover:shadow",
      secondary: "bg-blue-50 text-blue-500 hover:bg-blue-100",
      neutral: "bg-white text-black hover:bg-opacity-50",
      link: "text-primary !shadow-none",
      warning:
        "bg-orange-50 text-white hover:bg-orange-50/90 border border-orange-200 text-orange-400",
    },
    size: {
      default: "h-11 px-4 py-2",
      sm: "h-9 rounded-md px-4",
      lg: "h-12 rounded-md px-5 font-bold",
      icon: "h-10 w-10",
      flex: "flex items-center justify-center rounded-md font-medium",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
}

const buttonVariants = cva(
  "cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm shadow-sm transition-colors duration-200 disabled:pointer-events-none disabled:opacity-50 disabled:cursor-not-allowed without-ring gap-1",
  buttonVariantConfig,
)

/*
 *** Component
 */
// const genWarnings = genWarningsFactory('Button', buttonTrackers);

// const getAction = (asExternal: boolean): ButtonAction =>
//   asExternal ? buttonActions.OPEN : buttonActions.CLICK

// type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
//   asChild?: boolean
//   asLink?: boolean
//   asExternal?: boolean
//   newTab?: boolean
//   tracker: ButtonTracker
//   variant: ButtonVariant
//   size?: ButtonSize
// href?: string
// target?: string
// rel?: string
// }

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean
    asLink?: boolean
    asExternal?: boolean
    newTab?: boolean
    tracker?: AnalyticsTracker
    href?: string
    target?: string
    rel?: string
    prefetch?: boolean
  }

/**
 * Button component (with tracking in progress). Parent component must be a client component
 * @param {ButtonProps} props
 * @returns {React.ReactElement}
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      asLink = false,
      asExternal = false,
      newTab = false,
      tracker,
      variant,
      size,
      className,
      ...props
    },
    ref,
  ) => {
    const { trackEvent } = useAnalytics()
    const { href, ...propsWithoutHref } = props
    const Comp: React.ElementType = asLink ? Link : "button"

    // TODO! ADD THIS AFTER REBASE
    // const Comp: React.ElementType = asChild ? Slot : asLink ? Link : "button"

    let target, rel
    // external link or new tab
    if (asLink) {
      if (asExternal) {
        target = "_blank"
        rel = "noreferrer noopener"
      } else if (newTab) {
        target = "_blank"
      }
    }

    const c =
      variant === "naked"
        ? className
        : cn(buttonVariants({ variant, size, className }))

    return (
      <Comp
        className={c}
        ref={ref}
        target={target}
        rel={rel}
        {...(Comp === "button" ? propsWithoutHref : props)}
        onClick={(e) => {
          if (tracker) trackEvent(tracker)
          props.onClick?.(e)
        }}
      />
    )
  },
)

Button.displayName = "Button"

export { Button, buttonVariants, type ButtonAction, type ButtonTracker }
