"use client"

import { usePathname, useSearchParams } from "next/navigation"
import { useEffect } from "react"
import { useAnalytics } from "@store-platform/backend/api-client"

export function PageViewObserver() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { trackerReady, trackPage } = useAnalytics()

  useEffect(() => {
    if (trackerReady) trackPage(pathname, searchParams)
  }, [pathname, searchParams, trackerReady, trackPage])

  return null
}
